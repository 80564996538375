import '@/commons/create-config-hoc';
import '@/components/form-element';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider, theme as antTheme, App as AntApp } from 'antd';
import { StyleProvider, legacyLogicalPropertiesTransformer } from '@ant-design/cssinjs';
import {
  ComponentProvider,
  DrawerFunctionRender,
  getThemeJs,
  setIgnorePermission,
  InitMessageModalNotification,
  ModalFunctionRender,
} from '@rc-lib/pc';
import { BASE_NAME, IGNORE_PERMISSION } from '@/config';
import { LocaleProvider } from '@/locale';
import themeLess from '@/theme.module.less';
import pageConfigs, { conventionalRoutes } from '@/pages/page-configs';
import App from './App';
import './index.less';

const theme = getThemeJs(themeLess);
const { antPrefixCls, rcPrefixCls } = theme;

ConfigProvider.config({
  prefixCls: antPrefixCls, // 4.13.0+
  iconPrefixCls: antPrefixCls + 'icon', // 4.17.0+
});

setIgnorePermission(IGNORE_PERMISSION);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <BrowserRouter basename={BASE_NAME}>
    <LocaleProvider>
      <ConfigProvider
        theme={{
          token: theme,
          algorithm: antTheme.defaultAlgorithm,
        }}
        prefixCls={antPrefixCls}
        iconPrefixCls={antPrefixCls + 'icon'}
      >
        <ComponentProvider
          theme={theme}
          prefixCls={rcPrefixCls}
          pageConfigs={pageConfigs}
          conventionalRoutes={conventionalRoutes}
        >
          <StyleProvider
            hashPriority="high"
            transformers={[legacyLogicalPropertiesTransformer]}
          >
            <AntApp>
              <InitMessageModalNotification />
              <ModalFunctionRender />
              <DrawerFunctionRender />
              <App />
            </AntApp>
          </StyleProvider>
        </ComponentProvider>
      </ConfigProvider>
    </LocaleProvider>
  </BrowserRouter>,
  // </React.StrictMode>,
);
