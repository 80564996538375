import { extendFormElement } from '@rc-lib/pc';
import { Input } from 'antd';

// 扩展表单元素
extendFormElement([
  {
    type: 'demo',
    getComponent: (props) => {
      return <Input {...props} />;
    },
    componentName: 'Demo',
  },
]);
