import React from 'react';
import { createConfigHoc, modalFunctionHoc, modalHoc, drawerFunctionHoc, drawerHoc, isLoggedIn } from '@rc-lib/pc';
import { toLogin } from '@/commons';
import { withTranslation } from 'react-i18next';
import { ajaxHoc } from '@/commons/ajax';
import { APP_NAME } from '@/config';
import { conventionalRoutes } from '@/pages/page-configs';

// 弹框高阶组件默认属性
const defaultModalProps = {
  maskClosable: true,
  width: 600,
};

// 抽屉高级组件默认属性
const defaultDrawerProps = {
  maskClosable: true,
};

createConfigHoc(
  [
    // 必须放到顶部
    { name: 'modalFunction', hoc: modalFunctionHoc, defaultOptions: defaultModalProps },
    { name: 'modal', hoc: modalHoc, defaultOptions: defaultModalProps },
    { name: 'drawerFunction', hoc: drawerFunctionHoc, defaultOptions: defaultDrawerProps },
    { name: 'drawer', hoc: drawerHoc, defaultOptions: defaultDrawerProps },

    { name: 'auth', hoc: authHoc, defaultEnable: true, defaultOptions: undefined, covertTrue: false },
    { name: 'ajax', hoc: ajaxHoc, defaultOptions: { propName: 'ajax' }, defaultEnable: true },
    { name: 't', hoc: () => withTranslation(), defaultEnable: false },
    { name: 'title', hoc: titleHoc, defaultEnable: true },
    { name: 'memo', hoc: memoHoc, defaultEnable: true, defaultOptions: true },
  ],
  { conventionalRoutes },
);

/**
 * 给组件添加memo高阶组件，优化性能
 * @param enable
 * @returns {function(*): React.NamedExoticComponent<object>|*}
 */
function memoHoc(enable) {
  return (Component) => (enable ? React.memo(Component) : Component);
}

/**
 * 设置浏览器标题
 * @param title
 * @returns {function(*): function(*): *}
 */
function titleHoc(title) {
  return (Component) =>
    function WithTitle(props) {
      // console.log(props.t); // 如果title需要国际化
      document.title = `${title ? `${title} - ` : ''}${APP_NAME}`;
      return <Component {...props} />;
    };
}

/**
 * 登录拦截
 * @param auth
 * @param config
 * @returns {function(*): function(*): (*)}
 */
function authHoc(auth, config) {
  return (Component) =>
    function WithAuth(props) {
      if (!!config.title && auth === true && !isLoggedIn()) return toLogin();

      return <Component {...props} />;
    };
}
