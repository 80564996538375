import { t } from '@/locale';
import { getConventionalMenus } from '@rc-lib/pc';
import pageConfig, { conventionalRoutes } from '@/pages/page-configs';

// 父级目录无title，可以通过parentTitle指定
// 无法获取title的菜单配置
const TITLE_MAP = {
  system: t('系统管理'),
  example: t('示例'),
};

// const loop = (nodes) =>
//     nodes.map((node) => {
//         const { title, label, children } = node;
//         return {
//             ...node,
//             title: typeof title === 'string' ? t(title) : title,
//             label: typeof label === 'string' ? t(label) : label,
//             children: children ? loop(children) : undefined,
//         };
//     });

// 约定菜单：config高阶组件中需要配置title属性，否则不生成菜单；
const menus = getConventionalMenus(pageConfig, conventionalRoutes, TITLE_MAP);

export default menus;
// export default loop(menus);
