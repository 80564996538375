
        import { t } from '@/locale';
        import React from 'react';

const C0 = React.lazy(() => import('@/pages/about/index.jsx'));
const C1 = React.lazy(() => import('@/pages/example/demo/index.jsx'));
const C2 = React.lazy(() => import('@/pages/example/enum/index.jsx'));
const C3 = React.lazy(() => import('@/pages/example/locale/index.jsx'));
const C4 = React.lazy(() => import('@/pages/example/swagger-proxy/index.jsx'));
const C5 = React.lazy(() => import('@/pages/home/index.jsx'));
const C6 = React.lazy(() => import('@/pages/index.jsx'));
const C7 = React.lazy(() => import('@/pages/login/index-portal.jsx'));
const C8 = React.lazy(() => import('@/pages/login/index.jsx'));
const C9 = React.lazy(() => import('@/pages/system/users/index.jsx'));

export const conventionalRoutes =  [
    {
        "path": "/about",
        "exact": true,
        "Component": C0,
        "absComponent": "/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages/about/index.jsx"
    },
    {
        "path": "/example/demo",
        "exact": true,
        "Component": C1,
        "absComponent": "/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages/example/demo/index.jsx"
    },
    {
        "path": "/example/enum",
        "exact": true,
        "Component": C2,
        "absComponent": "/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages/example/enum/index.jsx"
    },
    {
        "path": "/example/locale",
        "exact": true,
        "Component": C3,
        "absComponent": "/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages/example/locale/index.jsx"
    },
    {
        "path": "/example/swagger-proxy",
        "exact": true,
        "Component": C4,
        "absComponent": "/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages/example/swagger-proxy/index.jsx"
    },
    {
        "path": "/home",
        "exact": true,
        "Component": C5,
        "absComponent": "/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages/home/index.jsx"
    },
    {
        "path": "/",
        "exact": true,
        "Component": C6,
        "absComponent": "/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages/index.jsx"
    },
    {
        "path": "/login/index-portal",
        "exact": true,
        "Component": C7,
        "absComponent": "/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages/login/index-portal.jsx"
    },
    {
        "path": "/login",
        "exact": true,
        "Component": C8,
        "absComponent": "/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages/login/index.jsx"
    },
    {
        "path": "/system/users",
        "exact": true,
        "Component": C9,
        "absComponent": "/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages/system/users/index.jsx"
    }
]

        export default [{
            filePath: '/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages/about/index.jsx',
            
            pagesPath: '/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages',
        
  title: t('关于我们'),
  layout: false,
},{
            filePath: '/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages/about/style.js',
            
            pagesPath: '/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages',
        },{
            filePath: '/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages/example/demo/index.jsx',
            
            pagesPath: '/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages',
        
},{
            filePath: '/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages/example/demo/main-action.js',
            
            pagesPath: '/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages',
        },{
            filePath: '/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages/example/enum/index.jsx',
            
            pagesPath: '/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages',
        
  auth: false,
  title: t('枚举数据'),
},{
            filePath: '/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages/example/locale/index.jsx',
            
            pagesPath: '/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages',
        
  title: t('国际化'),
  t: true,
},{
            filePath: '/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages/example/swagger-proxy/index.jsx',
            
            pagesPath: '/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages',
        
  title: 'swagger代理',
},{
            filePath: '/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages/home/index.jsx',
            
            pagesPath: '/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages',
        
},{
            filePath: '/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages/index.jsx',
            
            pagesPath: '/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages',
        },{
            filePath: '/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages/login/index-portal.jsx',
            
            pagesPath: '/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages',
        
  layout: false,
  auth: false,
},{
            filePath: '/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages/login/index.jsx',
            
            pagesPath: '/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages',
        
  layout: false,
  auth: false,
},{
            filePath: '/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages/page-configs.js',
            
            pagesPath: '/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages',
        },{
            filePath: '/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages/system/users/detailModal.jsx',
            
            pagesPath: '/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages',
        
  modalFunction: true,
},{
            filePath: '/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages/system/users/editModal.jsx',
            
            pagesPath: '/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages',
        
  modalFunction: true,
},{
            filePath: '/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages/system/users/index.jsx',
            
            pagesPath: '/Users/shizhaojun/workspace/private/demo/react_demo_20240109/src/pages',
        
  title: t('用户查询'),
}]
    