import { toHome } from '@/commons';
import { BASE_NAME, KEEP_PAGE_ALIVE } from '@/config';
import routes from '@/router/routes';
import { Error404, KeepPageAlive, ProgressLoading } from '@rc-lib/pc';
import { theme } from 'antd';
import { Suspense } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';

export default function Routes() {
  const { token } = theme.useToken();
  // 统一注入的props
  const ejectProps = {};

  const navigate = useNavigate();
  const error404 = (
    <Error404
      {...ejectProps}
      onGoHome={toHome}
      onGoBack={() => navigate(-1)}
    />
  );
  const element = useRoutes([
    ...routes.map((item) => {
      const { Component } = item;
      return {
        ...item,
        element: <Component {...ejectProps} />,
      };
    }),
    { path: '*', element: error404 },
  ]);

  return (
    <Suspense
      fallback={
        <ProgressLoading
          loading
          color={token.colorPrimary}
        />
      }
    >
      {KEEP_PAGE_ALIVE ? (
        <KeepPageAlive
          routes={routes}
          ejectProps={ejectProps}
          baseName={BASE_NAME}
          error404={error404}
        />
      ) : (
        element
      )}
    </Suspense>
  );
}
