import { Layout } from '@/components';
import { IS_SUB } from '@/config';
import Router from '@/router';
import themeLess from '@/theme.module.less';
import {
  Loading,
  modalDestroyAll,
  drawerDestroyAll,
  getThemeJs /*setPermission,*/,
  useComponentContext,
} from '@rc-lib/pc';
import { Modal } from 'antd';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
// import ajax from '@/commons/ajax';
import { getCurrentPageConfig } from '@/commons';

const theme = getThemeJs(themeLess);
const { layoutTheme } = theme;

export default function App() {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { loginUser, setContext } = useComponentContext();
  console.log('loginUser', loginUser);
  useEffect(() => {
    setContext({ loginUser: 123 });
  }, [setContext]);

  // 页面切换，关闭弹框
  useEffect(() => {
    Modal.destroyAll();
    modalDestroyAll();
    drawerDestroyAll();
  }, [location]);

  // 初始化一些数据
  useEffect(() => {
    (async () => {
      const { auth = true } = getCurrentPageConfig();
      if (!auth) return setLoading(false);

      try {
        // 获取用户权限码
        // const res = await ajax.get('/user/getUserCode', null, { setLoading });
        // setPermission(res.data || []);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (loading) {
    return (
      <div style={{ width: '100vw', height: '100vh' }}>
        <Loading loading />
      </div>
    );
  }

  return (
    <Layout
      theme={layoutTheme}
      defaultLayout={!IS_SUB}
    >
      <Router />
    </Layout>
  );
}
