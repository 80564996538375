import {
  toLogin as _toLogin,
  toHome as _toHome,
  createPCHandleError,
  createPCHandleSuccess,
  getCurrentPageConfig as _getCurrentPageConfig,
  getParentOrigin,
} from '@rc-lib/pc';
import { BASE_NAME, isInIframe } from '@/config';
import { getLocale } from '@/locale';
import pageConfigs, { conventionalRoutes } from '@/pages/page-configs';

/**
 * 跳转到登录页面
 * @returns {*}
 */
export function toLogin() {
  const path = isInIframe ? `${getParentOrigin()}/error-401.html` : '/login';
  return _toLogin({ path, baseName: BASE_NAME });
}

/**
 * 跳转到首页
 * @returns {*}
 */
export function toHome() {
  return _toHome({ path: '/', baseName: BASE_NAME });
}

/**
 * 错误提示
 * @param options
 * @returns {*}
 */
export function handleError(options) {
  const locale = getLocale().rcLocale;
  return createPCHandleError({ toLogin, locale })(options);
}

/**
 * 成功提示
 * @param options
 * @returns {*}
 */
export function handleSuccess(options) {
  const locale = getLocale().rcLocale;
  return createPCHandleSuccess({ locale })(options);
}

/**
 * 基于 window.location.pathname pageConfig 获取当前页面config高级组件参数
 * @returns {{}|*}
 */
export function getCurrentPageConfig() {
  let { pathname } = window.location;
  if (BASE_NAME) pathname = pathname.replace(BASE_NAME, '');

  return _getCurrentPageConfig(pageConfigs, conventionalRoutes, pathname) || {};
}
