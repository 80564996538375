import { ConfigProvider } from 'antd';
import { ComponentProvider } from '@rc-lib/pc';
import { getLocale } from '../util';

const { antLocale, rcLocale } = getLocale();

export default function LocaleProvider(props) {
  const { children } = props;

  return (
    <ConfigProvider locale={antLocale}>
      <ComponentProvider locale={rcLocale}>{children}</ComponentProvider>
    </ConfigProvider>
  );
}
