import pageConfig, { conventionalRoutes } from '@/pages/page-configs';
import { lazy } from 'react';

// 配置化路由
const configRoutes = pageConfig
  .filter((item) => !!item.path)
  .map((item) => {
    const { path, component, filePath } = item;

    return {
      path,
      exact: true,
      Component: lazy(component),
      absComponent: filePath,
    };
  });

// 约定路由
const convRoutes = conventionalRoutes.map((item) => {
  const config = pageConfig.find((it) => it.filePath === item.absComponent);
  return {
    ...item,
    keepAlive: config?.keepAlive,
  };
});

// 基于path去重
export default [...configRoutes, ...convRoutes].reduce((prev, curr) => {
  if (!prev.some((item) => item.path === curr.path)) prev.push(curr);
  return prev;
}, []);
