export default {
  '系统管理': 'System',
  '示例': 'Example',
  '国际化示例': 'Internationalization Example',
  '您确定要退出登录？': 'Are you sure you want to log out?',
  '个人中心': 'Personal Center',
  '退出登录': 'Log out',
  '请登录': 'Please login',
  '关于我们': 'About us',
  '测试{{0}}': 'Test {{0}}',
  '用户查询': 'User query',
  '国际化': 'i18n',
  '弹框示例': 'Model example',
  '前端': 'Front End',
};
