import { getLocale as _getLocale } from '@rc-lib/pc';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import dayjs from 'dayjs';
import ant_en_US from 'antd/lib/locale/en_US';
import ant_zh_CN from 'antd/lib/locale/zh_CN';
import rc_en_US from '@rc-lib/pc/es/locale/en_US';
import rc_zh_CN from '@rc-lib/pc/es/locale/zh_CN';
import _en_US from './lang/en_US';
import _zh_CN from './lang/zh_CN';

// https://react.i18next.com/
function i18nInit(lng) {
  i18n
    .use(initReactI18next)
    .init({
      resources: {
        en_US: { translation: _en_US },
        zh_CN: { translation: _zh_CN },
      },
      lng,
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    })
    .then();
}

export const t = i18n.t;

export const localeOptions = [
  { value: 'zh-CN', label: '🇨🇳 中文' },
  { value: 'en-US', label: '🇺🇸 English' },
];

export function getLocale() {
  // 暂时不启用国际化
  // const locale = 'zh-cn';
  const locale = _getLocale();

  // 英文
  if (locale.startsWith('en')) {
    require('dayjs/locale/en');
    dayjs.locale('en');
    i18nInit('en_US');
    return {
      antLocale: ant_en_US,
      rcLocale: rc_en_US,
    };
  }

  // 默认中文
  require('dayjs/locale/zh-cn');
  dayjs.locale('zh-cn');
  i18nInit('zh_CN');
  return {
    antLocale: ant_zh_CN,
    rcLocale: rc_zh_CN,
  };
}
